var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"lg":"7","cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.loadings.users}},[_c('v-card-title',[_vm._v("Рейтинг студентов")]),(!_vm.loadings.users)?_c('BarChart',{attrs:{"height":"200","keys":_vm.student_rates.slice(0, 10).map(function (el) { return el.name; }),"values":_vm.student_rates.map(function (el) { return el.score; })}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                        { text: '#', value: 'id' },
                        { text: 'Студент', value: 'name'},
                        { text: 'Институт', value: 'student_group.department.institute.name' },
                        { text: 'Кафедра', value: 'student_group.department.name' },
                        { text: 'Группа', value: 'student_group.name' },
                        { text: 'Рейтинг', value: 'score' }
                    ],"items":_vm.student_rates,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('UserLinkComponent',{attrs:{"user":item}})]}},{key:"item.score",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.score)+" ")])]}}])})],1)],1),_c('v-col',{attrs:{"lg":"5","cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.loadings.groups}},[_c('v-card-title',[_vm._v("Рейтинг студ. групп")]),(!_vm.loadings.groups)?_c('BarChart',{attrs:{"height":"200","keys":_vm.group_rates.slice(0, 10).map(function (el) { return el.name; }),"values":_vm.group_rates.map(function (el) { return el.score; })}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                        { text: '#', value: 'id' },
                        { text: 'Группа', value: 'name' },
                        { text: 'Институт', value: 'department.institute.name' },
                        { text: 'Рейтинг', value: 'score' }
                    ],"items":_vm.group_rates,"items-per-page":10},scopedSlots:_vm._u([{key:"item.score",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.score)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }